body{
    background-color: #6b21a8;
}

.headerHeroArea {
    height: 95vh !important;
}

.leftFirstAd{
    margin-left: 370px !important;
}

.indexShowsImages{
    height: 220px;
}

.newsHeader{
    height: 66vh !important;
}

.watchShowsHeader{
    height: 82vh !important;
}

.newBarnerImage{
    height: 430px !important;
    width: 85%;
}

.custom-link{
    border-bottom: 0.5px solid #A0A0A0;
    padding-bottom: 10px;
}

.newsAreaHeader{
    height: 320px !important;
}
.tvGuideShowsHeader{
    height: 270px !important; 
}

.anotherNewsArea{
    height: 252px !important;
}

.newsCardImage{
    height: 160px !important;
}

.videosImage{
    height: 190px !important;
}

.newsImage{
    width: 100% !important;  
}

.watchCard{
    height: 560px !important;
}